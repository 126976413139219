import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";

const EnterScenarioMetadata = ({ scenario, save, loading, saving }) => {
  const [scenarioName, setScenarioName] = useState("");
  const [userInstructions, setUserInstructions] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [botName, setBotName] = useState("");
  const [botStart, setBotStart] = useState("");

  useEffect(() => {
    if (scenario) {
      setScenarioName(scenario.name || "");
      setUserInstructions(scenario.user_instructions || "");
      setIsActive(scenario.is_active || false);
      setBotName(scenario.bot_name || "");
      setBotStart(scenario.bot_start || "");
    }
  }, [scenario]);

  return (
    <div className="prompt-generation-section">
      <h2 style={{ marginBottom: "10px" }}>Scenario info</h2>
      <div className="prompt-generation-input">
        <label htmlFor="scenario-name">Scenario name</label>
        <textarea
          className="prompt-generation-textarea"
          style={{ height: "fit-content" }}
          name="scenario-name"
          placeholder={loading ? "loading" : "Enter scenario name..."}
          value={scenarioName}
          onChange={(e) => setScenarioName(e.target.value)}
          disabled={loading || saving}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="user-instructions">User instructions</label>
        <textarea
          disabled={loading || saving}
          className="prompt-generation-textarea"
          name="user-instructions"
          placeholder={loading ? "loading" : "Enter user instructions..."}
          value={userInstructions}
          onChange={(e) => setUserInstructions(e.target.value)}
        />
      </div>
      <br />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              disabled={loading || saving}
              checked={isActive}
              onChange={() => setIsActive((prev) => !prev)}
              sx={{
                "&.Mui-checked": {
                  color: "#0ae5a1",
                },
              }}
            />
          }
          label="Active"
          labelPlacement="start"
          sx={{ m: 0 }}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="bot-name">Bot name</label>
        <textarea
          disabled={loading || saving}
          className="prompt-generation-textarea"
          style={{ height: "fit-content" }}
          name="bot-name"
          placeholder={loading ? "loading" : "Enter bot name..."}
          value={botName}
          onChange={(e) => setBotName(e.target.value)}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="bot-start">Bot start</label>
        <textarea
          disabled={loading || saving}
          className="prompt-generation-textarea"
          name="bot-start"
          placeholder={loading ? "loading" : "Enter bot start..."}
          value={botStart}
          onChange={(e) => setBotStart(e.target.value)}
        />
      </div>
      <br />
      <button
        className="prompt-generation-button"
        disabled={loading || saving}
        onClick={() => {
          save({
            ...scenario,
            name: scenarioName,
            user_instructions: userInstructions,
            is_active: isActive,
            bot_name: botName,
            bot_start: botStart,
          });
        }}
      >
        SAVE INFO
      </button>
    </div>
  );
};

export default EnterScenarioMetadata;
