//packages
import ReactGA from 'react-ga4';
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Tooltip from '@mui/material/Tooltip';
//styles
import "../style/chatHistory.css";
//stores
import chat from "../stores/chat.store";
//components
import Feedback from "../components/feedback";
import axios from "axios";
import ReviewPopup from "./popups/reviewPopup";
//consts
import { URL } from "../tools/url";
//tools
import { vh, vw } from "../tools/screen"
import EndChatPopup from "./popups/endChatPopup";
import ParamsReviewPopup from "./popups/socialParamReviewPopup";
import NoTokens from './popups/noTokens';
import UserFeedbackPopup from './popups/userFeedbackPopup';

const ChatComponent = ({
  allowSubmit,
  setAllowSubmit,
  reset,
  setReset,
  record,
  setRecord,
  speaking,
  setSpeechText,
  setEmotion,
  setDiDMODE,
  avatar,
  loadDisplay,
  endChatMode

}) => {
  // stores
  const chatStore = chat;

  // consts
  const ChatPlaceholder = "Type here...";
  const synth = window.speechSynthesis;
  const voicesList = synth.getVoices();
  const navigate = useNavigate();


  // states
  const [DevMode, setDevMode] = useState(false)
  const [reviewPopup, setReviewPopup] = useState(false)
  const [reviewParamsPopup, setReviewParamsPopup] = useState(false)
  const [loader, setLoader] = useState(false)
  const [chats, setChats] = useState([]);
  const [openFeedbacks, setOpenFeedbacks] = useState([]);
  const [currentChat, setCurrentChat] = useState("");
  const [feedbackDisplay, setFeedbackDisplay] = useState([]);
  const [reviewAns, setReviewAns] = useState([]);
  const [endChatPopupInfo, setEndChatPopupInfo] = useState({});
  const [endChatPopupDisplay, setEndChatPopupDisplay] = useState(false);
  const [noTokensPopup, setNoTokensPopup] = useState(false);
  const [userFeedbackPopup, setUserFeedbackPopup] = useState(false)



  //refs
  const ChatEndRef = useRef(null);
  const textareaRef = useRef(null);

  // hooks
  const {
    transcript,
    resetTranscript,
  } = useSpeechRecognition();

  // 

  useEffect(() => {
    let admin = localStorage.getItem('is_admin');
    if (admin === 'true') {
      setDevMode(true)
    }
    const checkTokens = async () => {
      const accessToken = localStorage.getItem('access_token');
      const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
      const { data } = await axios.get(`${URL}/api/checkTokens/?userId=${decodedToken.user_id}`);
      if (data.tokens <= 0) {
        setReset(true)
      }
    }
    checkTokens()
  }, [])

  useEffect(() => {
    if (avatar) {
      !speaking ? handleSynthesisEnd() : handleSynthesisStart();
    }
  }, [speaking])


  const startConversation = async () => {
    ReactGA.event({
      category: 'User',
      action: 'conversation start'
    });
    //makes sure to disable previous speech incidents
    synth.cancel();
    //starts displaying loader indicator
    setLoader(true);
    //starts bot turn and checks for bot response, after response turns loader off
    handleSynthesisStart();
    let botInitiatsConv = await chat.startChat();
    setLoader(false)
    //if the bot response is not null, it will be displayed and spoken (depending on the mode), else the user turn starts
    if (botInitiatsConv.res) {
      setChats([{ type: 'bot', message: botInitiatsConv.res }])
      if (!avatar) {
        textToVoice(botInitiatsConv.res);
      }
      else {
        setSpeechText(botInitiatsConv.res);
      }
    }
    else {
      if (!loadDisplay) {
        setRecord(true);
        SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
        setAllowSubmit(true);
        resetTranscript();
      }
    }
  }


  //forces scroll to bottom of chat display
  const scrollToBottom = () => {
    ChatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //activates scroll to bottom when a chat is added
  useEffect(() => {
    scrollToBottom();
  }, [chats]);
  useEffect(() => {
    scrollToBottom();
  }, [currentChat]);

  // forces scroll to bottom in the text input
  useEffect(() => {
    textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
  }, [transcript]);

  //Starts chat when popup is closed
  useEffect(() => {
    if (!loadDisplay) {
      startConversation();
    }
  }, [loadDisplay]);


  //resets chat states when the reset prop changes
  useEffect(() => {
    const resetFunc = async () => {
      setRecord(false)
      const checkTokens = async () => {
        try {
          const accessToken = localStorage.getItem('access_token');
          const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
          const { data } = await axios.get(`${URL}/api/checkTokens/?userId=${decodedToken.user_id}`);
          return data.tokens
        }
        catch (e) {
          return 1
        }
      }
      let tokens = await checkTokens()
      await resetChat();
      setDiDMODE(false)
      setFeedbackDisplay([]);
      synth.cancel();
      tokens > 0 ? startConversation() : setNoTokensPopup(true);
      setReset(false);
    }
    if (reset) {
      resetFunc()
    }
  }, [reset]);

  const resetChat = async () => {
    setChats([]);
    setCurrentChat("");
    resetTranscript();
    setOpenFeedbacks([]);
    setReviewAns([]);
    setEndChatPopupInfo();
    setEndChatPopupDisplay(false)
  }


  useEffect(() => {

    if (endChatPopupDisplay) {
      resetTranscript();
      setRecord(false);
      setAllowSubmit(false);
    }
  }, [endChatPopupDisplay])
  // speech to text mode activator
  useEffect(() => {
    record
      ? SpeechRecognition.startListening({ continuous: true, language: 'en-US' })
      : SpeechRecognition.stopListening();
  }, [record]);


  //when synthesis bot response starts talking
  const handleSynthesisStart = () => {
    setRecord(false);
    SpeechRecognition.stopListening();
    setAllowSubmit(false);
  };

  //when synthesis bot response finishes talking
  const handleSynthesisEnd = () => {
    if (endChatPopupInfo?.status && endChatPopupInfo?.status !== null && chats.length > 0) {
      setEndChatPopupDisplay(true);
    }
    else if (!loadDisplay) {
      setRecord(true);
      SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
      setAllowSubmit(true);
      resetTranscript();
    }
  };

  //sends user turn to server, and updates chats state with bot response
  let sendRes = async (fullMessage) => {
    ReactGA.event({
      category: 'User',
      action: 'sent user turn'
    });
    let botResponse = await chatStore.sendUserResponse(fullMessage);

    //server response performance code
    const serverResponse = Date.now();
    let addPerformance = { serverRes: (serverResponse - chatStore.performance.start) / 1000 }
    chatStore.setPerformance(addPerformance)

    //adds bot response to chats array and plays the response if not on d-Id mode
    let pastChats = [...chats]
    pastChats[pastChats.length - 1].feedbacks = botResponse.feedbacks;
    pastChats[pastChats.length - 1].id = botResponse.id;
    setFeedbackDisplay([...botResponse.feedbacks]);
    setTimeout(() => { setFeedbackDisplay({}) }, 5000 * botResponse.feedbacks.length)
    botResponse.chats.map((newChatItem) => {
      pastChats.push(newChatItem)
    })
    setChats(pastChats)

    let botTxt = botResponse.chats.find(res => res.type === 'bot');
    if (!avatar) {
      textToVoice(botTxt.message);
    }
    else {
      if (botTxt.message) {
        setSpeechText(botTxt.message)
        setEmotion(botResponse.emotion)
      }
      else {
        handleSynthesisEnd();

      }
      botTxt.message ? setSpeechText(botTxt.message) : handleSynthesisEnd();
    }

    if (botResponse.endChat?.status !== null) {
      ReactGA.event({
        category: 'User',
        action: 'conversation end'
      });
      setEndChatPopupInfo(botResponse.endChat)
    }
    return botTxt
  };

  // validates that the field is not empty, and handels the users turn info
  const handleData = async (fullMessage) => {
    if (fullMessage.trim() !== "" && allowSubmit) {

      //server response performance code
      const start = Date.now();
      let addPerformance = { start: start }
      chatStore.setPerformance(addPerformance)

      let tempChat = chats
      tempChat.push({ type: 'user', message: fullMessage })
      setChats(tempChat)
      sendRes(fullMessage);
      scrollToBottom();
    }
  };

  // submits user turn response when enter is pressed
  const handleKeyDown = (event) => {
    if (event.code === "Enter") {
      event.preventDefault();
      if (allowSubmit && (currentChat + transcript).trim() !== "") {
        setRecord(false);
        SpeechRecognition.stopListening();
        setAllowSubmit(false);
        let fullMessage = currentChat + transcript;
        resetTranscript();
        setCurrentChat("");
        handleData(fullMessage);
      }
    } else if (record) {
      SpeechRecognition.stopListening();
      setRecord(false);
      setCurrentChat(currentChat + transcript);
      resetTranscript();
    }
  };

  const textToVoice = (message) => {
    let voice = '';
    //check user platform
    if (navigator.userAgentData.platform === 'macOs') {
      let findVoice = voicesList.find(voice => voice.name === 'Karen');
      findVoice ? voice = findVoice : voice = voicesList[0]
    }
    else {
      let findVoice = voicesList.find(voice => voice.name === 'Google US English');
      findVoice ? voice = findVoice : voice = voicesList[0]
    }

    //Synthesis pauses after 15 sec speech. This breaks up all long paragraphs by sentance into different speeches to avoid bug
    if (!message || voice === '') { handleSynthesisEnd(); }
    else if (message.length > 100) {

      let messageArr = message.split('.');
      messageArr.map((msg, index) => {

        const utterThis = new SpeechSynthesisUtterance(msg);
        utterThis.onstart = handleSynthesisStart;
        if (index === messageArr.length - 1) {
          utterThis.onend = handleSynthesisEnd
        }
        utterThis.voice = voice;
        synth.speak(utterThis);
      })

    } else {
      const utterThis = new SpeechSynthesisUtterance(message);
      utterThis.onstart = handleSynthesisStart;
      utterThis.onend = handleSynthesisEnd;
      utterThis.voice = voice;
      synth.speak(utterThis);
    }
  };


  const handleFeedbackDisplay = (feedbackIndex) => {
    let updatedFeedbacks
    if (openFeedbacks.includes(feedbackIndex)) {
      updatedFeedbacks = openFeedbacks.filter(feedback => feedback !== feedbackIndex)
      setOpenFeedbacks(updatedFeedbacks)

    }
    else {
      updatedFeedbacks = [...openFeedbacks]
      updatedFeedbacks.push(feedbackIndex)
      setOpenFeedbacks(updatedFeedbacks)
    }
  }

  const updateReviewAns = (index) => {
    let tempAns = [...reviewAns]
    tempAns.push(index)
    setReviewAns(tempAns)
  }

  const copyToClipboard = () => {
    let copyItems = [];
    chats.map((chat) => {
      let text = `${chat.type}: ${chat.message} `;
      let status = chat?.status;
      copyItems.push(text + ' (' + status + ')')
    })
    //copies chats to clipboard
    navigator.clipboard.writeText(copyItems.join("  "))
  }

  const sendYesNoReview = async (turnId, skillId, success, type) => {
    let sendSkill;
    type === 'cue' ? sendSkill = { skillId: skillId, cueExpected: success } : sendSkill = { skillId: skillId, posFeedbackExpected: success }
    try {
      await axios.post(`${URL}/api/reportSkill/`,
        {
          'sessionId': chat.chatSettings.sessionId,
          'userTurnId': turnId,
          'skills': [sendSkill]
        });
    }
    catch (err) {
      console.log('err: ', err);
    }
  }

  return (
    <div className="chat-page">
      {noTokensPopup ?
        <div style={{ position: 'absolute', marginLeft: vw(-60) }}>
          <NoTokens close={() => {
            setNoTokensPopup(false); navigate("/course");
          }} />
        </div> : <></>}

      {endChatPopupDisplay ?
        <div className='instructions'>
          <EndChatPopup info={endChatPopupInfo} startConv={startConversation} setReset={setReset} setEndChatPopup={setEndChatPopupDisplay} />
        </div>
        : <></>}
      {userFeedbackPopup ?
        <div className='instructions'>
          <UserFeedbackPopup setUserFeedbackPopup={setUserFeedbackPopup} setRecord={setRecord} turnId={chats[chats.length - 1]?.id} />
        </div>
        : <></>}

      {/* this is the feedback component that displays cues, feedbacks and bot actions */}
      <Feedback feedbackDisplay={feedbackDisplay} />

      <div className="chat-history" style={endChatMode ? { height: vh(90) } : {}}>
        {loader ?
          <div id="wait" className="loader">
            <div id="bar1" className="bar"></div>
            <div id="bar2" className="bar"></div>
            <div id="bar3" className="bar"></div>
          </div> :
          <>
            <Tooltip title="Give feedback" placement="left-end">
              <img
                src={'/images/feedback2.png'}
                alt="feedback"
                onClick={() => {
                  setRecord(false);
                  setUserFeedbackPopup(true)
                }}
                className="feedbackIcon" />
            </Tooltip>
            <Tooltip title="Copy chat" placement="left-end">
              <img
                src={'/images/copy.png'}
                alt="copy"
                onClick={() => { copyToClipboard() }}
                className="copyIcon"
              />
            </Tooltip>

            {chats.map((chat, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="chatContainer"
                    style={chat.type === "bot" ? { backgroundColor: '#5FFACA', alignSelf: 'flex-end' } : {}}>
                    <h3>
                      {chat.message}
                    </h3>
                    <div className="feedbacks" >
                      {chat.feedbacks ?
                        chat.feedbacks.map((feedback) => {
                          return (
                            feedback.type === "cue" && feedback.display ?
                              <img
                                key={feedback}
                                src={feedback.display}
                                alt="cue"
                                onClick={() => { handleFeedbackDisplay(index) }}
                              />
                              : feedback.type === "cue" ?
                                <img
                                  key={feedback}
                                  src="/images/message.png"
                                  alt="cue"
                                  onClick={() => { handleFeedbackDisplay(index) }}
                                />
                                : <img
                                  key={feedback}
                                  src="/images/positiveFeedback.png"
                                  alt="star"
                                  onClick={() => { handleFeedbackDisplay(index) }}
                                />
                          )
                        })
                        : <></>}
                    </div>

                  </div>
                  {openFeedbacks.includes(index) ?
                    <div className="chatFeedbacks">
                      <div className="feedbackBody">
                        <img
                          className="assistant"
                          src="/images/assistant.png"
                          alt="assistant"
                        />
                        <div className="chatFeedbacksText">
                          <h2>{chat.feedbacks[0].title}</h2>
                          <h3>{chat.feedbacks[0].text} </h3>
                          {!reviewAns.includes(index) && DevMode ?
                            <div className="cueReview" style={{ marginLeft: vw(0), marginBottom: vh(4), marginTop: vh(-3) }}>
                              < img
                                onClick={() => {
                                  sendYesNoReview(chat.id, chat.feedbacks[0].id, true, chat.feedbacks[0].type)
                                  updateReviewAns(index)
                                }}
                                className="reviewButton"
                                src="/images/v.png"
                                alt="v"
                              />
                              <img
                                onClick={() => {
                                  sendYesNoReview(chat.id, chat.feedbacks[0].id, false, chat.feedbacks[0].type)
                                  updateReviewAns(index)
                                }}
                                className="reviewButton"
                                src="/images/x.png"
                                alt="x"
                              /> </div> : <></>}
                        </div>

                      </div>
                      <img
                        onClick={() => { handleFeedbackDisplay(index) }}
                        className="feedbackTextX"
                        src="/images/x_button.png"
                        alt="close"
                      />
                    </div> : <></>}
                  {chat.type !== "bot" && DevMode ?
                    <div className="cueReview">
                      {reviewPopup ?
                        <div className='instructions'>
                          <ReviewPopup userTurn={chat.id} close={setReviewPopup} />
                        </div> : <></>}

                      <img
                        onClick={() => {
                          setReviewPopup(true)
                        }}
                        className="reviewButton"
                        src="/images/plus.png"
                        alt="v"
                      />

                    </div> : chat.type === "bot" && DevMode ?
                      < div className="botOptions">
                        {reviewParamsPopup ?
                          <div className='instructions' style={{ direction: 'ltr' }}>
                            <ParamsReviewPopup params={chat.socialParams} userTurn={chat.id} close={setReviewParamsPopup} />
                          </div> : <></>}

                        <h3 className="chatState">state: {chat?.status}</h3 >
                        <h3 className="chatState">emotion: {chat?.emotion}</h3 >

                        <img
                          onClick={() => {
                            setReviewParamsPopup(true)
                          }}
                          className="botReviewButton"
                          src="/images/plus.png"
                          alt="add"
                        />
                      </div> : <></>
                  }

                </>
              );
            })}</>}
        <div ref={ChatEndRef} />
      </div >
      {!endChatMode ?
        <div className="chat-input">
          <textarea
            type="text"
            ref={textareaRef}
            aria-multiline={false}
            placeholder={ChatPlaceholder}
            value={record ? currentChat + transcript : currentChat}
            onChange={(event) => {
              setCurrentChat(event.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          {record ? (
            <img
              src="/images/recordOn.png"
              alt="Stop recording"
              className="mic-icon"
              onClick={() => {
                if (allowSubmit) {
                  SpeechRecognition.stopListening();
                  setRecord(false);
                  setCurrentChat(currentChat + transcript);
                  resetTranscript();
                }
              }}
            />
          ) : (
            <img
              src="/images/recordOff.png"
              alt="Record"
              className="mic-icon"
              onClick={() => {
                if (allowSubmit) {
                  resetTranscript();
                  setRecord(true);
                }
              }}
            />
          )}
          <img
            onClick={() => {
              if (allowSubmit && (currentChat || transcript)) {
                setRecord(false);
                SpeechRecognition.stopListening();
                setAllowSubmit(false);
                let fullMessage = currentChat + transcript;
                setCurrentChat("");
                resetTranscript();
                handleData(fullMessage);
              }
            }}
            img
            src="/images/send.png"
            alt="Send"
            className="send-icon"
          />
        </div> : <></>}
    </div >
  );
};

export default ChatComponent;
