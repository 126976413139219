//packages
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from 'react';
import axios from "axios";
//consts
import { URL, ADMINMODE } from "../tools/url";
//stores
import chat from '../stores/chat.store';




function PrivateRoute({ Component, props }) {
    const [location, setLocation] = useState('/login')

    const navigate = useNavigate();

    useEffect(() => {
        const getUserInfo = async () => {

            const accessToken = localStorage.getItem('access_token');
            if (accessToken) {

                const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
                //get user info from database
                const { data } = await axios.get(`${URL}/api/getUserInfo/?id=${decodedToken.user_id}`);
                chat.setName(data.userInfo.first_name)
                chat.setEmail(data.userInfo.username)
                chat.setSessionId(data.userInfo.username)
                localStorage.setItem('is_admin', data.userInfo.is_admin);
                if (!data.userInfo.is_admin && ADMINMODE) {
                    navigate('/underMaintenance');
                }
                setLocation('')
            }
            else {
                navigate('/login');
            }
            setLocation(window.location.pathname)
        }
        getUserInfo();
    });

    const rend = useMemo(() => {
        let r = true;
        if (props) {
          r = !props.admin || JSON.parse(localStorage.getItem("is_admin")); // only render if this isn't an admin-only component, or if the user is an admin
        }
        return location !== "/login" && r;
    }, [location, props]);

    return (<>
        {rend ? <Component props={props} /> : <></>}
    </>
    );
}

export default PrivateRoute;
