import axios from "axios";
import { URL } from "../../../tools/url";
import { useState, useEffect } from "react";
import useErrorPopup from "../../../tools/hooks/showError";
import "../../../style/filterBar.css";
import Graph from "./Graph";
import { Line } from "recharts";
import { groupBy, countRetries, fillNullDates, GraphFilterOptions, GraphNames } from "./KPIUtils.jsx";


const ConversationKPIs = ({ currentLocation, children }) => {
  const [userOptions, setUserOptions] = useState();
  const [status, setStatus] = useState();
  const [graphData, setGraphData] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [showError, ErrorPopupComponent] = useErrorPopup();
  const [user, setUser] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [lines, setLines] = useState(<Line type="monotone" dataKey="Conversation Count" stroke="#82ca9d" />);


  // get all the users from the DB and insert them into the dropdown user select
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/getUsersList/`);
      setUserOptions(data?.users.map(user => {
        return (<option key={user.username} value={user.username}>{user.username}</option>)}));
    } catch (err) {
      console.error(err);
      showError("Couldn't get users");
    }
  };

  // send the given information to get the relevant user scenarios to show
  const handleSubmit = async (event) => {
    event.preventDefault();
    // initializations
    let graphDataArr = [];
    setGraphData(graphDataArr);
    setSubmit(false);
    const form = event.target;
    try {
      // get the relevant user scenarios given the user and status
      const data = await axios.get(`${URL}/api/getUserScenariosStatuses/?id=${form[0].value}&status=${form[1].value}`);
      const user_scenarios = data.data.user_scenarios;
      const users_num = form[0].value === GraphFilterOptions.ALL_USERS ? form[0].length - 1 : 1;
      // group by date and count
      const user_scenarios_by_date = groupBy(user_scenarios, us => us.date);
      const dates = [...new Set(user_scenarios.map(us => us.date))];
      if (form[1].value === GraphFilterOptions.RETRY) {
        graphDataArr = countRetries(user_scenarios_by_date, dates, users_num);
      } else {
        for (const date of dates) {
          const user_scenarios_in_date = user_scenarios_by_date.get(date);
          let user_scenarios_num = user_scenarios_in_date.length / users_num;
          graphDataArr.push({"name": date, "Conversation Count": user_scenarios_num});
        }
      }
      if (graphDataArr.length) {
        fillNullDates(graphDataArr, dates, GraphNames.CONVERSATION);
      }
      setGraphData(graphDataArr);
      if (graphDataArr.length) {
        setShowMessage(false);
        setSubmit(true);
      } else {
        setShowMessage(true);
      }
      console.log("data.user_scenarios", data.data.user_scenarios);
    } catch (err) {
      console.error(err);
      showError("Couldn't show graph");
    }
  };

  // set the users list and skill list
  useEffect(() => {
    fetchUsers();
  }, []);

  // prevent illegal requests
  useEffect(() => {
    setSubmit(false);
  }, [user, status]);

  return (
    <div>
      <form onSubmit={handleSubmit} className="flexbox-container">
        <div className="filter-item">
          <label className="filter-bar">Select user:</label>
          <select className="rectangle-object" name="selected_user" onChange={e => setUser(e.target.value)}>
            <option value={GraphFilterOptions.ALL_USERS}>- {GraphFilterOptions.ALL_USERS} -</option>
            { userOptions }
          </select>
        </div>
        <div className="filter-item">
          <label className="filter-bar">Select status:</label>
          <select className="rectangle-object" name="selected_status" onChange={e => setStatus(e.target.value)}>
            <option value={GraphFilterOptions.ALL_STATUSES}>- {GraphFilterOptions.ALL_STATUSES} -</option>
            <option value={GraphFilterOptions.ONGOING}>{GraphFilterOptions.ONGOING}</option>
            <option value={GraphFilterOptions.FINISHED}>{GraphFilterOptions.FINISHED}</option>
            <option value="Success">Finished with success</option>
            <option value="Failure">Finished with failure</option>
            <option value={GraphFilterOptions.RETRY}>{GraphFilterOptions.RETRY}</option>
          </select>
        </div>
        <button className="show-graph" type="submit">Show conversation count graph</button>
      </form>
      <Graph graphData={graphData} lines={lines} submit={submit} showMessage={showMessage} />
    </div>
  );
};

export default ConversationKPIs;
