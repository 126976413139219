import { useEffect, useState } from "react";
import AdminPage from "../components/admin/AdminPage";
import EnterScenarioMetadata from "../components/admin/course/edit-scenario/EnterScenarioMetadata";
import ScenarioConditionList from "../components/admin/course/edit-scenario/ScenarioConditionList";
import ScenarioStateList from "../components/admin/course/edit-scenario/ScenarioStateList";
import "../style/editCourse.css";
import axios from "axios";
import { URL } from "../tools/url";
import useErrorPopup from "../tools/hooks/showError";
import { useParams } from "react-router-dom";

const EditScenario = () => {
  const [scenario, setScenario] = useState(null);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const url_params = useParams();

  useEffect(() => {
    const fetchScenarioData = async (scenarioId) => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${URL}/api/ceGetEditableScenarioInfo/?scenarioId=${scenarioId}`
        );
        setScenario(data.scenario);
      } catch (err) {
        console.error(err);
        showError("Couldn't fetch scenario data");
      }
      setLoading(false);
    };
    if (url_params && url_params?.id) {
      fetchScenarioData(url_params?.id);
    }
  }, [url_params]);

  const saveScenario = async (scenarioData) => {
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveScenario/`, {
        ...scenarioData,
      });
      setScenario(data);
      showError("Saved!", "success");
    } catch (err) {
      console.error(err);
      showError("Couldn't save scenario");
    }
  };

  return (
    <AdminPage currentLocation="Edit scenario">
      <div className="prompt-generation-container">
        <h1>Edit scenario</h1>
        <EnterScenarioMetadata
          scenario={scenario}
          save={saveScenario}
          loading={loading}
          saving={saving}
        />
        <ScenarioStateList
          scenario={scenario}
          setScenario={setScenario}
          save={saveScenario}
          loading={loading}
          saving={saving}
        />
        <ScenarioConditionList
          scenario={scenario}
          setScenario={setScenario}
          save={saveScenario}
          loading={loading}
          saving={saving}
        />
      </div>
      {ErrorPopupComponent}
    </AdminPage>
  );
};

export default EditScenario;
