import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../style/newUserForm.css"; // CSS for styling

const NewUserForm = () => {

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        birthdate: new Date(),
        gender: "",
        occupation: [],
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        birthdate: "",
        gender: "",
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            birthdate: date,
        });
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...errors };

        if (formData.firstName.trim() === "") {
            newErrors.firstName = "First Name is required";
            valid = false;
        } else {
            newErrors.firstName = "";
        }

        if (formData.lastName.trim() === "") {
            newErrors.lastName = "Last Name is required";
            valid = false;
        } else {
            newErrors.lastName = "";
        }

        if (!formData.birthdate) {
            newErrors.birthdate = "Birthdate is required";
            valid = false;
        } else {
            newErrors.birthdate = "";
        }

        if (formData.gender === "") {
            newErrors.gender = "Gender is required";
            valid = false;
        } else {
            newErrors.gender = "";
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            // Handle form submission here
            // console.log(formData);
        } else {
            console.log("Form has errors. Please correct them.");
        }
    };

    return (
        <div className="formContainer">
            <h2 className='formTitle'>Help us get to know you</h2>

            <form onSubmit={handleSubmit} className="registration-form">
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                    />
                    <span className="error">{errors.firstName}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                    />
                    <span className="error">{errors.lastName}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="birthdate">Birthdate</label>
                    <DatePicker selected={formData.birthdate} onChange={handleDateChange} />
                    <span className="error">{errors.birthdate}</span>
                </div>

                <div className="form-group-gender">
                    <label>Gender</label>
                    <div className="gender">
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="male"
                                onChange={handleInputChange}
                                required
                            />{" "}
                            Male
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="female"
                                onChange={handleInputChange}
                                required
                            />{" "}
                            Female
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="other"
                                onChange={handleInputChange}
                                required
                            />{" "}
                            Other
                        </label>
                    </div>
                    <span className="error">{errors.gender}</span>
                </div>

                <div className="form-group">
                    <label>Occupation</label>
                    <div className="occupation-options">
                        {/* Add your checkboxes for occupation here */}
                    </div>
                </div>
                <div className="next">
                    <h2>Next</h2>
                </div>
            </form>
        </div>
    );
};


export default NewUserForm;
