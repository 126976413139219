//packages
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import PageHeader from "../components/header";
//consts
import { URL } from "../tools/url";
import { vh, vw } from "../tools/screen";
//style
import '../style/courses.css'
import CoursesSkeleton from "../components/skeletons/coursesSk";
//stores
import chat from "../stores/chat.store";



const Courses = () => {

    const navigate = useNavigate();

    //useState that will hold course data
    const [load, setLoad] = useState(true)
    const [courses, setCourses] = useState([]);


    useEffect(() => {
        fetchData();
    }, []);

    //fetches courses data from the server
    const fetchData = async () => {
        try {
            setLoad(true)
            const { data } = await axios.get(`${URL}/api/getCoursesList/`)
            setCourses([...data.courses])
            setLoad(false)
        } catch (error) {
            console.error("Error retrieving data:", error);
        }
    };

    const navigateToCourse = (courseId) => {
        localStorage.setItem('courseId', courseId)
        navigate('./course')
    }


    return (
        <div style={{ width: vw(100), height: vh(100), overflow: 'hidden' }}>

            <PageHeader />
            <h2 className="CoursesTitle">Hi {chat.chatSettings.name}! Select a course to practice your skills:</h2>
            {load ?
                <div className="coursesContainerSK">
                    <CoursesSkeleton />
                </div> :
                <div className="coursesContainer">
                    {courses.map((course) => {
                        return (
                            <div className="courseCardWrap">
                                <div
                                    key={course.Desc}
                                    className="courseCard"
                                    // style={course.courseId !== "peers for young adults" && !DevMode ? { opacity: .5, zIndex: -2 } : {}}
                                    onClick={() => {
                                        // if (course.courseId === "peers for young adults") {
                                        navigateToCourse(course.courseId)
                                        // }
                                        // else if (DevMode) {
                                        //     navigateToCourse(course.courseId)
                                        // }
                                    }
                                    }>
                                    <h3>{course.courseName}</h3>
                                    <p>{course.courseDesc}</p>
                                    <div id="courseImgWrap">
                                        {course.courseId === "careers by arrows" ? <img className="courseImg" src="/images/workCourse.png" alt="work" /> : <img className="courseImg" style={{ height: vh(7) }} src="/images/peersCourse.png" alt="peers" />}
                                    </div>
                                    <div className="decorativeCardLine"></div>

                                </div>
                                {/* {course.courseId !== "peers for young adults" && !DevMode ? <img id="comingSoon" src="/images/comingSoon.png" /> : <></>} */}
                            </div>
                        )
                    })}
                </div>}

        </div>
    );
};

export default Courses;
