//packages
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//stores
import chat from "../stores/chat.store";
//components
import ChatLoader from "../components/popups/chatLoadingPopup";
import CoachChatHistory from "../components/coachChatHistory";
import UnityAvatar from "../components/unityAvatar";
import ChatComponentHebrew from '../components/chatHistoryHeb';
import ChatBarHeb from '../components/chatBarHeb';
//style
import "../style/chat.css";


const ChatPageHebrew = (props) => {
  // stores
  const chatStore = chat;

  // states
  const [reset, setReset] = useState(false);
  const [record, setRecord] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [speechText, setSpeechText] = useState('');
  const [emotion, setEmotion] = useState('');
  const [open, setOpen] = useState(false); //to close modal
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [DidMODE, setDiDMODE] = useState(false);
  const [avatar, setAvatar] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadDisplay, setLoadDisplay] = useState(true);
  const [endChatMode, setEndChatMode] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/chatPageDev') {
      setAvatar(false)
      setLoadDisplay(false)
    }
    setEndChatMode(false)
  }, [])

  useEffect(() => {
    if (!speaking && avatar && !loadDisplay) {
      setAllowSubmit(true)
      setRecord(true)
    }
  }, [speaking, avatar, loadDisplay])

  let goBack = async () => {
    chatStore.endChat();
    navigate("/course");
  }

  //this function is passed down to the chatBar component, and is executed there
  const resetChat = () => {
    setReset(true);
    chatStore.endChat();
  };

  return (
    <div className="chat-page">
      {avatar && loadDisplay ?
        <ChatLoader
          setLoadDisplay={setLoadDisplay}
          loading={loading}
          setLoading={setLoading}
          setAvatar={setAvatar} />
        : <></>}

      <div className="container">
        {!endChatMode ? <div className="left">
          <div className="centered">
            <div className="back" onClick={() => { goBack() }}>
              <img src="/images/back.png" alt="back" />
              <h2>חזרה לדף הבית</h2>
            </div>

            <div className="avatarDisplay">
              <UnityAvatar
                setSpeaking={setSpeaking}
                speechText={speechText}
                setSpeechText={setSpeechText}
                setEmotion={setEmotion}
                emotion={emotion}
                avatar={avatar}
                setLoading={setLoading}
                Loading={loading}

              />
            </div>

            <div className="chatBar">
              <ChatBarHeb chatRestart={resetChat} open={open} setOpen={setOpen} />
            </div>
          </div>
        </div> :
          <>
            <CoachChatHistory />
          </>}

        <div className="right">
          <ChatComponentHebrew
            chatRestart={resetChat}
            allowSubmit={allowSubmit}
            setAllowSubmit={setAllowSubmit}
            reset={reset}
            setReset={setReset}
            record={record}
            setRecord={setRecord}
            speaking={speaking}
            setSpeaking={setSpeaking}
            setSpeechText={setSpeechText}
            setEmotion={setEmotion}
            DidMODE={DidMODE}
            setDiDMODE={setDiDMODE}
            avatar={avatar}
            loadDisplay={loadDisplay}
            endChatMode={endChatMode}
            setEndChatMode={setEndChatMode}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatPageHebrew;
