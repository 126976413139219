import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { URL } from "../tools/url";
import { decodeJSONFromServer } from "../tools/admin_enums";
import AdminPage from "../components/admin/AdminPage";
import ClassificationQuestionDefinition from "../components/admin/classification-question/ClassificationQuestionDefinition";
import TestSet from "../components/admin/classification-question/TestSet";
import PromptTable from "../components/admin/classification-question/PromptTable";
import "../style/promptGeneration.css";
import useErrorPopup from "../tools/hooks/showError";

/**
 * This is the central component for the details for classification questions.
 *
 * Inside, there are 3 sections - question info, tests, and prompt results.
 *
 * All info is stored in the ```builder``` object, which changes when:
 * * The builder is initialized in the beginning, when calling the server ```init_classification_builder``` endpoint
 * * The ```save``` function is called, calling the ```save_classification_builder``` endpoint - saving the new builder and changing the old one
 */
const ClassificationQuestionData = () => {
  const [builder, setBuilder] = useState(null);

  const url_params = useParams();

  const [questionNames, setQuestionNames] = useState([]);

  const [callingServer, setCallingServer] = useState(false);

  const [loadingDefinition, setLoadingDefinition] = useState(false);
  const [loadingTests, setLoadingTests] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(false);

  const [unsavedDefinitionChanges, setUnsavedDefinitionChanges] =
    useState(false);
  const [unsavedTestChanges, setUnsavedTestChanges] = useState(false);
  const [unsavedPromptChanges, setUnsavedPromptChanges] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  useEffect(() => {
    const fetchQuestions = async (id) => {
      setLoadingDefinition(true);
      setLoadingTests(true);
      setLoadingPrompts(true);
      setCallingServer(true);
      try {
        // TODO make into fetch that gets 1 question
        const questionRes = await axios.get(
          `${URL}/api/get_all_classification_questions`
        );

        const decodedQuestions = decodeJSONFromServer(
          questionRes?.data?.classification_questions
        );

        if (!decodedQuestions) throw new Error("Couldn't fetch questions");

        const question = decodedQuestions?.find((q) => q.id == id);

        setQuestionNames(
          decodedQuestions
            ?.map((item) => (item.id == id ? undefined : item.name))
            .filter((item) => item)
        );

        if (!question) throw new Error(`Question not found, id ${id}`);

        const { data } = await axios.post(
          `${URL}/api/init_classification_builder/`,
          {
            classification_question_id: id,
          }
        );
        if (data) {
          setBuilder(decodeJSONFromServer({ ...data }));
        } else throw new Error("Couldn't initiate builder");
      } catch (err) {
        console.error(err);
        showError(err);
      }
      setLoadingDefinition(false);
      setLoadingTests(false);
      setLoadingPrompts(false);
      setCallingServer(false);
      setUnsavedDefinitionChanges(false);
      setUnsavedTestChanges(false);
      setUnsavedPromptChanges(false);
    };
    if (url_params && url_params?.id) {
      fetchQuestions(url_params?.id);
    }
  }, [url_params]);

  useEffect(() => {
    console.log("%c%s", "color: red; font-size: 20px;", "builder changed!!");
    console.log(builder);
  }, [builder]);

  const saveBuilder = async (builderObj) => {
    setCallingServer(true);
    try {
      const { data } = await axios.post(
        `${URL}/api/save_classification_builder/`,
        {
          ...builderObj,
        }
      );
      if (data) {
        setBuilder(decodeJSONFromServer(data));
        showError("Successfully saved!", "success");
      }
    } catch (err) {
      console.error(err);
      showError("Couldn't save builder");
    }
    setCallingServer(false);
  };

  return (
    <>
      <AdminPage>
        <div className="prompt-generation-container">
          <h1>
            Classification question data:
            {builder?.question?.name ? (
              ` "${builder?.question?.name}"`
            ) : (
              <i style={{ fontStyle: "italic" }}> loading...</i>
            )}
          </h1>

          {/* Question name and definition */}
          <ClassificationQuestionDefinition
            builder={builder}
            setBuilder={setBuilder}
            save={saveBuilder}
            loading={loadingDefinition}
            existingNames={questionNames}
            callingServer={callingServer}
            setCallingServer={setCallingServer}
            unsavedDefinitionChanges={unsavedDefinitionChanges}
            unsavedTestChanges={unsavedTestChanges}
            unsavedPromptChanges={unsavedPromptChanges}
            setUnsavedDefinitionChanges={setUnsavedDefinitionChanges}
          />

          {/* Tests */}
          <TestSet
            builder={builder}
            setBuilder={setBuilder}
            save={saveBuilder}
            loading={loadingTests}
            callingServer={callingServer}
            setCallingServer={setCallingServer}
            unsavedDefinitionChanges={unsavedDefinitionChanges}
            unsavedTestChanges={unsavedTestChanges}
            unsavedPromptChanges={unsavedPromptChanges}
            setUnsavedTestChanges={setUnsavedTestChanges}
          />

          {/* Table of prompts */}
          <PromptTable
            builder={builder}
            setBuilder={setBuilder}
            save={saveBuilder}
            loading={loadingPrompts}
            callingServer={callingServer}
            setCallingServer={setCallingServer}
            unsavedDefinitionChanges={unsavedDefinitionChanges}
            unsavedTestChanges={unsavedTestChanges}
            unsavedPromptChanges={unsavedPromptChanges}
            setUnsavedPromptChanges={setUnsavedPromptChanges}
          />
        </div>
      </AdminPage>
      {ErrorPopupComponent}
    </>
  );
};

export default ClassificationQuestionData;
